


























































import { User } from '@app/models';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Route } from 'vue-router';

import { user } from '../../store/modules/user-module';

@Component
export default class AccountSettings extends Vue {
  @Prop({ type: Object })
  public user!: User.Settings;

  public async passwordAccount() {
    this.$router.push({ name: 'account-password' });
  }

  public async deleteAccount() {
    const del = await this.$bce.confirm(
      this.$t('are-you-sure'),
      this.$t('user-delete-confirmation'),
      { cancel: this.$t('cancel'), ok: this.$t('delete') }
    );

    if (!del) return;

    this.$router.push('/');
    await this.$firebase.account.delete(this.$user.id);
    await this.$user.signOut();

    this.$bce.message(
      this.$t('item-is-deleted', { item: this.$t('account') }),
      10
    );
  }
}
